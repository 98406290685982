import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import T from 'prop-types'
import { flexWithGapColumn, ListTitle, EventItem } from 'gatsby-components'

const useEventListStyles = makeStyles(theme => ({
  wrapper: {
    ...flexWithGapColumn(theme)(3),
    margin: theme.spacing(2, 0, 0, 0),
    padding: 0,
  },
}))

const EventList = ({
  events,
  className,
  component,
  highlightedEvent,
  listTitleColor,
}) => {
  const { wrapper } = useEventListStyles()

  // Don't display events before the cutoff date.
  const eventCutoff = new Date()
  // Set the cutoff date to three days before current date.
  eventCutoff.setDate(eventCutoff.getDate() - 3)

  return (
    <Box className={className}>
      <ListTitle paletteColor={listTitleColor} title="Upcoming Events" />
      <Box className={wrapper} component={component} data-test-id="event-list">
        {highlightedEvent}
        {events()
          .filter(e => Date.parse(e.startTime) > eventCutoff)
          .slice(0, 4)
          .map(event => (
            <EventItem event={event} key={event.uid} />
          ))}
      </Box>
    </Box>
  )
}

EventList.propTypes = {
  component: T.string,
  events: T.func,
  className: T.string,
  highlightedEvent: T.node,
  listTitleColor: T.arrayOf(T.string),
}

EventList.defaultProps = {
  component: 'ul',
  events: () => {},
  className: undefined,
  highlightedEvent: undefined,
  listTitleColor: ['secondary', 'dark'],
}

export default EventList
