import React from 'react'
import {
  Button,
  Grid,
  Typography,
  withStyles,
  Box,
  Hidden,
  useMediaQuery,
} from '@material-ui/core'
import { Link as RouterLink } from 'gatsby'
import {
  PaddedContainer,
  PageSection,
  flexWithGapRow,
  flexWithGapColumn,
  EditorsPicksList,
} from 'gatsby-components'

import SEO from 'gatsby-components/src/components/SEO'
import PersonalizedLists from '../components/list/personalized-lists'
import MostRecentArticles from '../components/list/MostRecentArticles'
import EventList from 'gatsby-components/src/components/cms/list/EventList'
import GainKnowledgeLinks from 'gatsby-components/src/components/cms/list/GainKnowledgeLinks'

import events from '../data/events'

import HeroImageWrapper from '../components/layout/HeroImageWrapper'

function KnowledgeHome({ classes }) {
  const Wrapper = useMediaQuery('(min-width:600px)')
    ? HeroImageWrapper
    : React.Fragment

  return (
    <Wrapper>
      <SEO title="Knowledge Base Home Page" />
      <div className={classes.header}>
        <PaddedContainer className={classes.heroDescription}>
          <Grid container spacing={3} direction="column">
            <Grid item xs={12} sm={4}>
              <Typography variant="h1">
                Gain knowledge from the world's leading organisations
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="body2">
                EFQM pride themselves on their knowledge base. We want you our
                members to learn and improve and see how your company can reach
                excellence.
              </Typography>
            </Grid>
            <Grid item container spacing={3} direction="row">
              <Grid item>
                <Button
                  color="secondary"
                  variant="outlined"
                  component={RouterLink}
                  to="/my-content/add"
                >
                  Submit to knowledge base
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </PaddedContainer>
      </div>
      <PageSection>
        <Box className={classes.primaryArticleLists}>
          <EditorsPicksList />
          <PersonalizedLists />
        </Box>
      </PageSection>
      <Hidden xsDown implementation="css">
        <PageSection
          className={classes.mostRecentAndEvents}
          paletteColor={['background', 'light']}
        >
          <MostRecentArticles className={classes.mostRecent} />
          <EventList events={events} className={classes.eventList} />
        </PageSection>
        <PageSection>
          <GainKnowledgeLinks />
        </PageSection>
      </Hidden>
    </Wrapper>
  )
}

const styles = theme => ({
  header: {
    paddingBottom: '140px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '0px',
    },
  },
  heroDescription: {
    marginTop: theme.spacing(13),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  primaryArticleLists: flexWithGapColumn(theme)(6),
  mostRecentAndEvents: flexWithGapRow(theme)(5),
  eventList: {
    flexBasis: '35%',
    minWidth: 0,
  },
  mostRecent: {
    flexBasis: '65%',
    minWidth: 0,
  },
})

export default withStyles(styles, { withTheme: true })(KnowledgeHome)
